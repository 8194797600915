import { useState } from "react";

import CardGenerator from "./CardGenerator";
import Intro from "./Intro";
import Scroller from "./Scroller";

const Home = ({ baseDomain }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showScroll, setShowScroll] = useState(false);
  return (
    <main>
      <Intro
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        showScroll={showScroll}
        setShowScroll={setShowScroll}
      />
      {showScroll && (
        <>
          <Scroller name={name} />
          <CardGenerator baseDomain={baseDomain} name={name} email={email} />
        </>
      )}
    </main>
  );
};

export default Home;
