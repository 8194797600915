import { useEffect } from "react";
import imgMenuClose from "assets/images/_lunaai/hamburger-menu-close.svg";
import { motion } from "framer-motion";

import catAnimationData from "assets/images/ULUXlqrO0S.json";
import LottieLoader from "react-lottie-loader";
import imgEb from "assets/images/logo.svg";

const Loader = ({ images, type, isPreResultError, preResultError, t }) => {
  useEffect(() => {
    document.documentElement.classList.add("noscroll");

    return () => {
      document.documentElement.classList.remove("noscroll");
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="backdrop loader d-flex justify-content-center align-items-center   w-100">
      {type === "error" && (
        <div
          onClick={() => isPreResultError(false)}
          className="close position-absolute end-0 top-0 p-5">
          <img src={imgMenuClose} />
        </div>
      )}

      <div className="loader w-100">
        <LottieLoader animationData={catAnimationData} />
        {/*         <div>
          <p className="fw-bold">Pišem...</p>
          <motion.img style={{ width: "100px" }} src={imgEb} />
        </div> */}

        {type === "fetching" && <></>}
        {type === "error" && (
          <>
            <h2 className="text-white">Napaka</h2>

            <button
              onClick={() => isPreResultError(false)}
              className="btn btn-yellow mt-3 py-3 px-4 text-uppercase text-white">
              Ponovno
            </button>
          </>
        )}
      </div>

      <img
        className={`bottom-racek  position-absolute  d-xl-block ${
          type === "error" && "bottom-racek__hide"
        }`}
        src={images["bg-racek-loader.png"]}
      />
    </motion.div>
  );
};

export default Loader;
