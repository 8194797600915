import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";

import { preloadImages } from "utils/utils.js";

//import imgLogo from "@/assets/images/logo.svg";

gsap.registerPlugin(ScrollTrigger);

const Scroller = ({ name }) => {
  const grid = useRef(null);

  let lenis;

  //Function to initialize Lenis for smooth scrolling
  const initSmoothScrolling = () => {
    // Instantiate the Lenis object with specified properties
    lenis = new Lenis({
      lerp: 0.1, // Lower values create a smoother scroll effect
      smoothWheel: true, // Enables smooth scrolling for mouse wheel events
    });

    // Update ScrollTrigger each time the user scrolls
    lenis.on("scroll", () => ScrollTrigger.update());

    // Define a function to run at each animation frame
    const scrollFn = (time) => {
      lenis.raf(time); // Run Lenis' requestAnimationFrame method
      requestAnimationFrame(scrollFn); // Recursively call scrollFn on each frame
    };
    // Start the animation frame loop
    requestAnimationFrame(scrollFn);
  };

  // Function to apply scroll-triggered animations to a given gallery
  const applyAnimation = (grid, animationType) => {
    // Child elements of grid
    const gridWrap = grid.current.querySelector(".grid-wrap");
    const gridItems = grid.current.querySelectorAll(".grid__item");
    const gridItemsInner = [...gridItems].map((item) =>
      item.querySelector(".grid__item-inner")
    );

    // Define GSAP timeline with ScrollTrigger
    const timeline = gsap.timeline({
      defaults: { ease: "none" },
      scrollTrigger: {
        trigger: gridWrap,
        start: "top bottom+=5%",
        end: "bottom top-=5%",
        scrub: true,
      },
    });

    // Apply different animations based on type
    switch (animationType) {
      case "type3":
        // Set some CSS related style values

        grid.current.style.setProperty("--grid-width", "105%");
        grid.current.style.setProperty("--grid-columns", "8");
        grid.current.style.setProperty("--perspective", "1500px");
        grid.current.style.setProperty("--grid-inner-scale", "0.5");

        timeline
          .set(gridItems, {
            transformOrigin: "50% 0%",
            z: () => gsap.utils.random(-5000, -2000),
            rotationX: () => gsap.utils.random(-65, -25),
            filter: "brightness(0%)",
          })
          .to(
            gridItems,
            {
              xPercent: () => gsap.utils.random(-150, 150),
              yPercent: () => gsap.utils.random(-300, 300),
              rotationX: 0,
              filter: "brightness(200%)",
            },
            0
          )
          .to(
            gridWrap,
            {
              z: 6500,
            },
            0
          )
          .fromTo(
            gridItemsInner,
            {
              scale: 2,
            },
            {
              scale: 0.5,
            },
            0
          );

        break;

        // Set some CSS related style values
        grid.current.style.setProperty("--perspective", "2500px");
        grid.current.style.setProperty("--grid-width", "100%");
        grid.current.style.setProperty("--grid-gap", "6");
        grid.current.style.setProperty("--grid-columns", "3");
        grid.current.style.setProperty("--grid-item-ratio", "1");

        timeline.fromTo(
          gridItems,
          {
            transformOrigin: "50% 200%",
            rotationX: 0,
            yPercent: 400,
          },
          {
            yPercent: 0,
            rotationY: 360,
            opacity: 0.2,
            scale: 0.8,
            stagger: 0.03,
          }
        );

        break;

      default:
        console.error("Unknown animation type.");
        break;
    }
  };

  // Apply animations to each grid
  const scroll = () => {
    let animationType = "type3";
    if (grid.current) {
      applyAnimation(grid, animationType);
    }
  };

  // Preload images, initialize smooth scrolling, apply scroll-triggered animations, and remove loading class from body
  useEffect(() => {
    preloadImages(".grid__item-inner").then(() => {
      //initSmoothScrolling();
      scroll();

      //document.body.classList.remove("loading");
    });
  }, []);

  return (
    <section className="content content--spacing">
      <div className="grid grid--3" ref={grid}>
        <div className="grid-wrap">
          {[...Array(11)].map((_, i) => (
            <div className="grid__item" key={i}>
              <div
                className="grid__item-inner"
                style={{ backgroundImage: `url(img/${i + 1}.jpg)` }}
              />
            </div>
          ))}
          {[...Array(11)].map((_, i) => (
            <div className="grid__item" key={i}>
              <div
                className="grid__item-inner"
                style={{ backgroundImage: `url(img/${i + 1}.jpg)` }}
              />
            </div>
          ))}
          {[...Array(11)].map((_, i) => (
            <div className="grid__item" key={i}>
              <div
                className="grid__item-inner"
                style={{ backgroundImage: `url(img/${i + 1}.jpg)` }}
              />
            </div>
          ))}
          {[...Array(11)].map((_, i) => (
            <div className="grid__item" key={i}>
              <div
                className="grid__item-inner"
                style={{ backgroundImage: `url(img/${i + 1}.jpg)` }}
              />
            </div>
          ))}
          {[...Array(11)].map((_, i) => (
            <div className="grid__item" key={i}>
              <div
                className="grid__item-inner"
                style={{ backgroundImage: `url(img/${i + 1}.jpg)` }}
              />
            </div>
          ))}
        </div>
      </div>
      <h3 className="content__title content__title--center content__title--bottom text-white">
        <span>
          <span className="gradient-text">{name},</span>
          <br />
          si predstavljaš,
          <br />
          kako bi izgledal
          <br />
          tvoj praznik?
        </span>
      </h3>
    </section>
  );
};

export default Scroller;
