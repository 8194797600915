// Reacts & Libs
import { useState, useEffect, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { setStory } from "redux/storySlice";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";

import { useTranslation } from "react-i18next";

// Utils
import { importAll } from "utils/importImages";
import { trackEvent } from "views/Home/tracking";

// Components

import Form from "views/Home/Form";
import Loader from "views/Home/Loader";

function CardGenerator({ baseDomain, name, email }) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledMsg, setDisabledMsg] = useState("");
  const [error, setError] = useState(false);
  const [countResults, setCountResults] = useState(null);
  const [tags, setTags] = useState([]);

  const URL =
    process.env.NODE_ENV === "production" ? undefined : "http://localhost:4000";

  const [promptVar, updatePromptVar] = useReducer(
    (prev, next) => {
      const newPromptVar = { ...prev, ...next };

      return newPromptVar;
    },
    {
      firstname: name,
      email,
      barva: "",
      zival: "",
      interesi: [],
      letnicas: "",
    }
  );

  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  const images = importAll(
    require.context("assets/images", false, /\.(png|jpe?g|svg)$/)
  );

  const generateText = async () => {
    if (
      promptVar.firstname === "" ||
      promptVar.barva === "" ||
      promptVar.zival === "" ||
      promptVar.letnicas === ""

      // || promptVar.lesson === ""
    ) {
      goToStart();
      setDisabled(true);
      return;
    }
    const captchaToken =
      localStorage.getItem("captcha") === "success"
        ? "success"
        : await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    setDisabled(false);
    setLoading(true);

    try {
      const res = await axios.post(`${baseDomain}/api`, {
        promptVar,
        captchaToken,
      });

      localStorage.setItem("captcha", "success");
      dispatch(setStory(res.data.jobId));

      setLoading(true);

      trackEvent("generate");

      /*       setTimeout(() => {
        navigate(`/result/${res.data.jobId}`);
      }, 10000); */

      const socket = io(URL);
      socket.on("job completed", (data) => {
        if (res.data.jobId === data.jobId) {
          trackEvent("result");

          setTimeout(() => {
            navigate(`/result/${res.data.jobId}`);
          }, 300);
        }
      });
    } catch (err) {
      console.log("error", err);
      setError(true);
    }
  };

  async function getCountResults() {
    try {
      let data = await axios.get(`${baseDomain}/countResults`);

      setCountResults(data.data);
    } catch (err) {
      console.log(`ERROR: ${err}`);
    }
  }

  const handleChangeAge = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      updatePromptVar({
        age: e.target.value,
      });
    }
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i, tag) => {
    setTags(tags.filter((tag, index) => index !== i));

    const updatedInterests = promptVar.interesi.filter((_, idx) => idx !== i);

    // Update the state with the new array of interests
    updatePromptVar({ ...promptVar, interesi: updatedInterests });
  };

  const handleAddition = (tag) => {
    if (tags.length < 2) {
      setTags([...tags, tag]);
      const updatedInterests = [...promptVar.interesi, tag.text];

      // Update the state with the new array of interests
      updatePromptVar({ ...promptVar, interesi: updatedInterests });
    }

    /*     updatePromptVar({
      interesi: tag.text,
    });
 */
  };

  useEffect(() => {
    getCountResults();
  }, []);

  useEffect(() => {
    updatePromptVar({ firstname: name });
  }, [name]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}>
      <Form
        images={images}
        promptVar={promptVar}
        updatePromptVar={updatePromptVar}
        handleChangeAge={handleChangeAge}
        generateText={generateText}
        loading={loading}
        disabled={disabled}
        disabledMsg={disabledMsg}
        tags={tags}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        recaptchaRef={recaptchaRef}
        name={name}
      />

      {loading && <Loader images={images} type="fetching" t={t} />}
      {error && (
        <Loader
          images={images}
          type="error"
          error={error}
          setError={setError}
          t={t}
        />
      )}
    </motion.div>
  );
}

function goToStart() {
  // Get the section element by ID
  const section = document.getElementById("form");

  // Scroll to the section using scrollIntoView()
  section.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  // Prevent the default link behavior
  return false;
}

export default CardGenerator;
