import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { WithContext as ReactTags } from "react-tag-input";

import imgDividerVeliki from "assets/images/divider veliki.svg";
import imgDividerMali from "assets/images/divider mali.svg";

const Form = ({
  images,
  promptVar,
  updatePromptVar,
  generateText,
  loading,
  disabled,
  disabledMsg,
  tags,
  delimiters,
  handleDelete,
  handleAddition,
  recaptchaRef,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <div className="position-relative" id="form">
      <div className="container narrower  rounded-5 text-center pt-5 px-lg-5 ">
        <img className="divider" src={imgDividerVeliki} />

        <h1 className="gradient-text">{name},</h1>
        <h2 className="px-md-6" style={{ fontSize: "25px" }}>
          povej nam, kaj zvabi nasmeh na tvoj obraz, naš AI pomočnik, pa bo
          ustvaril praznik po tvoji meri.
        </h2>

        <img className="divider" src={imgDividerVeliki} />

        {disabled && (
          <p className="mt-2 fw-bold text-pink-dark mb-5">
            Izolnite vsa polja.
          </p>
        )}
        {disabledMsg !== "" && (
          <p className="mt-2 fw-bold text-orange mb-5">{disabledMsg}</p>
        )}
        <div className="form mt-4">
          <div
            className={`group my-2 my-md-5  ${
              promptVar.barva === "" && disabled && "error"
            }`}>
            <div className="my-2 my-md-5  barve">
              <div className="mb-3">
                <h3 className="">Najljubša barva:</h3>
              </div>

              <div className="row g-0 g-sm-4 d-flex justify-content-center radio-image">
                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="barva"
                    id="rumena"
                    value="rumena"
                    checked={promptVar.barva === "rumena"}
                    onChange={(e) =>
                      updatePromptVar({
                        barva: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="rumena">
                    <img className="" src={images["rumena.svg"]} />
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="barva"
                    id="rdeca"
                    value="rdeca"
                    checked={promptVar.barva === "rdeca"}
                    onChange={(e) =>
                      updatePromptVar({
                        barva: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="rdeca">
                    <img className="" src={images["rdeca.svg"]} />
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="barva"
                    id="vijolicna"
                    value="vijolicna"
                    checked={promptVar.barva === "vijolicna"}
                    onChange={(e) =>
                      updatePromptVar({
                        barva: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="vijolicna">
                    <img className="" src={images["vijolicna.svg"]} />
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="barva"
                    id="modra"
                    value="modra"
                    checked={promptVar.barva === "modra"}
                    onChange={(e) =>
                      updatePromptVar({
                        barva: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="modra">
                    <img className="" src={images["modra.svg"]} />
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="barva"
                    id="zelena"
                    value="zelena"
                    checked={promptVar.barva === "zelena"}
                    onChange={(e) =>
                      updatePromptVar({
                        barva: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="zelena">
                    <img className="" src={images["zelena.svg"]} />
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="barva"
                    id="crna"
                    value="crna"
                    checked={promptVar.barva === "crna"}
                    onChange={(e) =>
                      updatePromptVar({
                        barva: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="crna">
                    <img className="" src={images["crna.svg"]} />
                  </label>
                </div>
              </div>
            </div>

            <img className="divider" src={imgDividerMali} />

            <div className="my-2 my-md-5  zival">
              <div className="mb-3">
                <h3 className="">Najljubša žival:</h3>
              </div>

              <div className="row g-0 g-sm-4 d-flex justify-content-center radio-image">
                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="zival"
                    id="tiger"
                    value="tiger"
                    checked={promptVar.zival === "tiger"}
                    onChange={(e) =>
                      updatePromptVar({
                        zival: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="tiger">
                    <img className="" src={images["tiger.svg"]} />
                    <p>tiger</p>
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="zival"
                    id="koala"
                    value="koala"
                    checked={promptVar.zival === "koala"}
                    onChange={(e) =>
                      updatePromptVar({
                        zival: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="koala">
                    <img className="" src={images["koala.svg"]} />
                    <p>Koala</p>
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="zival"
                    id="kuscar"
                    value="kuscar"
                    checked={promptVar.zival === "kuscar"}
                    onChange={(e) =>
                      updatePromptVar({
                        zival: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="kuscar">
                    <img className="" src={images["kuscar.svg"]} />
                    <p>Kuščar</p>
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="zival"
                    id="lenivec"
                    value="lenivec"
                    checked={promptVar.zival === "lenivec"}
                    onChange={(e) =>
                      updatePromptVar({
                        zival: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="lenivec">
                    <img className="" src={images["lenivec.svg"]} />
                    <p>Lenivec</p>
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="zival"
                    id="riba"
                    value="riba"
                    checked={promptVar.zival === "riba"}
                    onChange={(e) =>
                      updatePromptVar({
                        zival: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="riba">
                    <img className="" src={images["riba.svg"]} />
                    <p>Riba</p>
                  </label>
                </div>

                <div className="col-4 col-md-2">
                  <input
                    type="radio"
                    name="zival"
                    id="kokos"
                    value="kokos"
                    checked={promptVar.zival === "kokos"}
                    onChange={(e) =>
                      updatePromptVar({
                        zival: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="kokos">
                    <img className="" src={images["kokos.svg"]} />
                    <p>Kokoš</p>
                  </label>
                </div>
              </div>
            </div>

            <img className="divider" src={imgDividerMali} />

            <div className="my-5">
              <div className="mb-3">
                <h3 className="">Interesi:</h3>
                <p>
                  Napiši dve svoji najljubši aktivnosti ali interesa, ki ti
                  vedno narišeta nasmeh na obraz.
                </p>
              </div>
              <ReactTags
                tags={tags}
                autofocus={false}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                inputFieldPosition="bottom"
                allowDragDrop={false}
                placeholder="Vpiši in pritisni enter"
                autocomplete
                classNames={{
                  tags: "tagsClass",
                  tagInput: "tagInputClass",
                  tagInputField: "form-control",
                  selected: "selectedClass",
                  remove: "removeClass",
                  suggestions: "suggestionsClass",
                  activeSuggestion: "activeSuggestionClass",
                  editTagInput: "editTagInputClass",
                  editTagInputField: "editTagInputField",
                  clearAll: "clearAllClass",
                }}
              />
              <div className="react-tags-wrapper d-none">
                <span className="tag-wrapper suggestion">
                  Žongliranje
                  <button
                    onClick={() =>
                      handleAddition({ id: "Žongliranje", text: "Žongliranje" })
                    }
                    className="removeClass"
                    type="button"
                    aria-label="Tag at index 0 with value sfgfg focussed. Press backspace to remove">
                    +
                  </button>
                </span>
                <span className="tag-wrapper suggestion">
                  Vrtnarjenje
                  <button
                    onClick={() =>
                      handleAddition({ id: "Vrtnarjenje", text: "Vrtnarjenje" })
                    }
                    className="removeClass"
                    type="button"
                    aria-label="Tag at index 0 with value sfgfg focussed. Press backspace to remove">
                    +
                  </button>
                </span>
                <span className="tag-wrapper suggestion">
                  Striženje ovac
                  <button
                    onClick={() =>
                      handleAddition({
                        id: "Striženje ovac",
                        text: "Striženje ovac",
                      })
                    }
                    className="removeClass"
                    type="button"
                    aria-label="Tag at index 0 with value sfgfg focussed. Press backspace to remove">
                    +
                  </button>
                </span>
                <span className="tag-wrapper suggestion">
                  Štetje prometa
                  <button
                    onClick={() =>
                      handleAddition({
                        id: "Štetje prometa",
                        text: "Štetje prometa",
                      })
                    }
                    className="removeClass"
                    type="button"
                    aria-label="Tag at index 0 with value sfgfg focussed. Press backspace to remove">
                    +
                  </button>
                </span>
              </div>
            </div>

            <img className="divider" src={imgDividerMali} />

            <div className="my-2 my-md-5 zival">
              <div className="mb-3">
                <h3 className="">Najljubši letni čas:</h3>
              </div>

              <div className="row g-0 g-sm-4 d-flex justify-content-center radio-image">
                <div className="col-3 col-md-2">
                  <input
                    type="radio"
                    name="letnicas"
                    id="zima"
                    value="zima"
                    checked={promptVar.letnicas === "zima"}
                    onChange={(e) =>
                      updatePromptVar({
                        letnicas: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="zima">
                    <img className="" src={images["zima.svg"]} />
                    <p>Zima</p>
                  </label>
                </div>

                <div className="col-3 col-md-2">
                  <input
                    type="radio"
                    name="letnicas"
                    id="pomlad"
                    value="pomlad"
                    checked={promptVar.letnicas === "pomlad"}
                    onChange={(e) =>
                      updatePromptVar({
                        letnicas: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="pomlad">
                    <img className="" src={images["pomlad.svg"]} />
                    <p>Pomlad</p>
                  </label>
                </div>

                <div className="col-3 col-md-2">
                  <input
                    type="radio"
                    name="letnicas"
                    id="poletje"
                    value="poletje"
                    checked={promptVar.letnicas === "poletje"}
                    onChange={(e) =>
                      updatePromptVar({
                        letnicas: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="poletje">
                    <img className="" src={images["poletje.svg"]} />
                    <p>Poletje</p>
                  </label>
                </div>

                <div className="col-3 col-md-2">
                  <input
                    type="radio"
                    name="letnicas"
                    id="jesen"
                    value="jesen"
                    checked={promptVar.letnicas === "jesen"}
                    onChange={(e) =>
                      updatePromptVar({
                        letnicas: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="jesen">
                    <img className="" src={images["jesen.svg"]} />
                    <p>Jesen</p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <img className="divider" src={imgDividerVeliki} />

          <div className="row d-flex justify-content-center my-2 my-md-5">
            <h1 className="gradient-text">{name}</h1>
            <h2 style={{ fontSize: "25px" }}>
              ostal ti je samo še en korak.
              <br />
              Zaženi praznik mašinerijo ...
            </h2>
            <div className="mt-4 mt-md-5">
              <button
                onClick={() => generateText()}
                className={`btn btn-orange px-4 py-3 text-white d-flex align-items-center justify-content-center  mx-auto px-5`}
                type="button"
                disabled={loading && "disabled"}>
                {loading && (
                  <span
                    className="spinner-border spinner-border-md me-3"
                    role="status"
                    aria-hidden="true"></span>
                )}

                {loading ? "Generiram" : "Zaženi"}
              </button>
            </div>
          </div>
          <div className="text-center d-inline-block mt-3">
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              badge="inline"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
