import { getLocalStorage } from "utils/getLocalStorage";

const hasAnalytics = getLocalStorage("cookies-analytics");

const trackEvent = (event) => {
  //Plausible
  window.plausible(event);
};

export { trackEvent };
