import { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import imgLogo from "assets/images/logo.svg";
import imgDivider from "assets/images/divider veliki.svg";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
  exit: { opacity: 0, transition: { duration: 1 } },
};

const fadeInScale = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: "tween", mass: 0.3, delay: 1 },
  },
  exit: { opacity: 0, transition: { duration: 0.5, delay: 2.5 } },
};

const Intro = ({
  name,
  setName,
  email,
  setEmail,
  showScroll,
  setShowScroll,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const inputRefName = useRef(null);
  const inputRefEmail = useRef(null);

  const handleInputChange = (event, type) => {
    if (type === "name") {
      setName(event.target.value);
    }
    if (type === "email") {
      setEmail(event.target.value);
    }
  };

  const showScrollHandler = () => {
    if (name !== "" && email !== "") {
      setShowScroll(true);
    } else {
      if (name === "") {
        inputRefName.current.focus();
      } else if (email === "") {
        inputRefEmail.current.focus();
      }
    }
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 100 && isVisible) {
      setIsVisible(false);
    } else if (currentScrollPos <= 100 && !isVisible) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowForm(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible]);

  return (
    <>
      <AnimatePresence mode="wait">
        {!showScroll ? (
          <motion.div
            key="intro"
            variants={fadeIn}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="intro intro__bg">
            <AnimatePresence mode="wait">
              {!showForm ? (
                <motion.div
                  key="1"
                  variants={fadeInScale}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="logo">
                  <img src={imgLogo} />
                </motion.div>
              ) : (
                <motion.div
                  key="2"
                  variants={fadeIn}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="form d-flex justify-content-center">
                  <div className="col-10">
                    <img className="divider" src={imgDivider} />
                    <div>
                      <div className="mb-0">
                        <h3 className="">Tvoje ime:</h3>
                      </div>
                      <input
                        ref={inputRefName}
                        placeholder="Vpiši tukaj"
                        className="form-control text-center"
                        name="name"
                        autocomplete="off"
                        id="name"
                        value={name}
                        required
                        onChange={(event) => handleInputChange(event, "name")}
                      />
                    </div>

                    <div className="mt-5">
                      <div className="mb-0">
                        <h3 className="">Tvoj e-naslov:</h3>
                      </div>
                      <input
                        ref={inputRefEmail}
                        type="email"
                        placeholder="Vpiši tukaj"
                        className="form-control text-center"
                        name="email"
                        autocomplete="off"
                        id="email"
                        value={email}
                        required
                        onChange={(event) => handleInputChange(event, "email")}
                      />
                    </div>
                    <img className="divider" src={imgDivider} />
                    <button
                      onClick={() => showScrollHandler()}
                      className={`btn btn-orange py-3 text-white d-flex align-items-center justify-content-center mx-auto px-5 mt-md-6`}
                      type="button">
                      Naprej
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ) : (
          <motion.div
            key="scroll"
            className="intro scroll d-flex justify-content-center align-items-end"
            variants={fadeIn}
            initial="hidden"
            animate="visible"
            exit="exit">
            <div className={isVisible ? "visible" : "hidden"}>
              <p className="text-center">Scroll</p>
              <svg
                width="42"
                height="60"
                viewBox="0 0 42 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.93 59.8C26.481 59.8 31.8046 57.5949 35.7298 53.6698C39.6549 49.7446 41.86 44.421 41.86 38.87V20.93C41.86 15.379 39.6549 10.0554 35.7298 6.13026C31.8046 2.20512 26.481 0 20.93 0C15.379 0 10.0554 2.20512 6.13026 6.13026C2.20512 10.0554 0 15.379 0 20.93V38.87C0 44.421 2.20512 49.7446 6.13026 53.6698C10.0554 57.5949 15.379 59.8 20.93 59.8ZM2.99 20.93C2.99 16.172 4.88011 11.6089 8.24451 8.24451C11.6089 4.88011 16.172 2.99 20.93 2.99C25.688 2.99 30.2511 4.88011 33.6155 8.24451C36.9799 11.6089 38.87 16.172 38.87 20.93V38.87C38.87 43.628 36.9799 48.1911 33.6155 51.5555C30.2511 54.9199 25.688 56.81 20.93 56.81C16.172 56.81 11.6089 54.9199 8.24451 51.5555C4.88011 48.1911 2.99 43.628 2.99 38.87V20.93Z"
                  fill="white"
                />
                <path
                  d="M19.4351 29.1525H19.5696L19.8686 29.4664C20.0076 29.6066 20.173 29.7178 20.3551 29.7937C20.5373 29.8696 20.7327 29.9087 20.9301 29.9087C21.1274 29.9087 21.3228 29.8696 21.505 29.7937C21.6872 29.7178 21.8525 29.6066 21.9915 29.4664L22.2905 29.1525H22.4251V29.0179L30.9615 20.4964L28.8386 18.3735L22.4251 24.802V8.96997H19.4351V24.802L13.0215 18.3735L10.8986 20.4964L19.4351 29.0179V29.1525Z"
                  fill="white"
                />
              </svg>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Intro;
